define("discourse/plugins/discourse-ai/initializers/related-topics", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "discourse-ai-related-topics",
    initialize(container) {
      const settings = container.lookup("service:site-settings");
      if (settings.ai_embeddings_enabled && settings.ai_embeddings_semantic_related_topics_enabled) {
        (0, _pluginApi.withPluginApi)("1.1.0", api => {
          api.modifyClass("model:post-stream", {
            pluginId: "discourse-ai",
            _setSuggestedTopics(result) {
              this._super(...arguments);
              if (!result.related_topics) {
                return;
              }
              this.topic.setProperties({
                related_topics: result.related_topics
              });
            }
          });
        });
      }
    }
  };
});