define("discourse/plugins/discourse-ai/discourse/components/ai-helper-loading", ["exports", "discourse/components/d-button", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _dButton, _i18n, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const AiHelperLoading = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div class="ai-helper-loading">
      <div class="dot-falling"></div>
      <span>
        {{i18n "discourse_ai.ai_helper.context_menu.loading"}}
      </span>
      <DButton
        @icon="times"
        @title="discourse_ai.ai_helper.context_menu.cancel"
        @action={{@cancel}}
        class="btn-flat cancel-request"
      />
    </div>
  
  */
  {
    "id": "/zGtkuLq",
    "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"ai-helper-loading\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"dot-falling\"],[12],[13],[1,\"\\n    \"],[10,1],[12],[1,\"\\n      \"],[1,[28,[32,0],[\"discourse_ai.ai_helper.context_menu.loading\"],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[8,[32,1],[[24,0,\"btn-flat cancel-request\"]],[[\"@icon\",\"@title\",\"@action\"],[\"times\",\"discourse_ai.ai_helper.context_menu.cancel\",[30,1]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@cancel\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/ai-helper-loading.js",
    "scope": () => [_i18n.default, _dButton.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  var _default = _exports.default = AiHelperLoading;
});