define("discourse/plugins/discourse-ai/discourse/connectors/topic-more-content/related-topics", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "I18n"], function (_exports, _component, _object, _service, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class _default extends _component.default {
    static shouldRender(args) {
      return (args.model.related_topics?.length || 0) > 0;
    }
    static #_ = (() => dt7948.g(this.prototype, "store", [_service.inject]))();
    #store = (() => (dt7948.i(this, "store"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "site", [_service.inject]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "moreTopicsPreferenceTracking", [_service.inject]))();
    #moreTopicsPreferenceTracking = (() => (dt7948.i(this, "moreTopicsPreferenceTracking"), void 0))();
    listId = "related-topics";
    get hidden() {
      return this.moreTopicsPreferenceTracking.selectedTab !== this.listId;
    }
    static #_4 = (() => dt7948.n(this.prototype, "hidden", [(0, _object.computed)("moreTopicsPreferenceTracking.selectedTab")]))();
    get relatedTopics() {
      return this.args.outletArgs.model.related_topics.map(topic => this.store.createRecord("topic", topic));
    }
    registerList() {
      this.moreTopicsPreferenceTracking.registerTopicList({
        name: _I18n.default.t("discourse_ai.related_topics.pill"),
        id: this.listId,
        icon: "discourse-sparkles"
      });
    }
    static #_5 = (() => dt7948.n(this.prototype, "registerList", [_object.action]))();
    removeList() {
      this.moreTopicsPreferenceTracking.removeTopicList(this.listId);
    }
    static #_6 = (() => dt7948.n(this.prototype, "removeList", [_object.action]))();
  }
  _exports.default = _default;
});