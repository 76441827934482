define("discourse/plugins/discourse-ai/discourse/components/ai-llm-selector", ["exports", "@ember/object", "discourse-i18n", "select-kit/components/combo-box"], function (_exports, _object, _discourseI18n, _comboBox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _comboBox.default.extend({
    _modelDisabledChanged: (0, _object.observer)("attrs.disabled", function () {
      this.selectKit.options.set("disabled", this.get("attrs.disabled.value"));
    }),
    content: (0, _object.computed)(function () {
      return [{
        id: "blank",
        name: _discourseI18n.default.t("discourse_ai.ai_persona.no_llm_selected")
      }].concat(this.llms);
    }),
    selectKitOptions: {
      filterable: true
    }
  });
});