define("discourse/plugins/discourse-ai/discourse/components/ai-persona-tool-options", ["exports", "@glimmer/component", "discourse-i18n", "discourse/plugins/discourse-ai/discourse/components/ai-persona-tool-option-editor", "@ember/component", "@ember/template-factory"], function (_exports, _component, _discourseI18n, _aiPersonaToolOptionEditor, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AiPersonaToolOptions extends _component.default {
    get showToolOptions() {
      const allTools1 = this.args.allTools;
      if (!allTools1) {
        return false;
      }
      return this.toolNames.any(tool1 => allTools1.findBy("id", tool1)?.options);
    }
    get toolNames() {
      if (!this.args.tools) {
        return [];
      }
      return this.args.tools.map(tool1 => {
        if (typeof tool1 === "string") {
          return tool1;
        } else {
          return tool1[0];
        }
      });
    }
    get toolOptions() {
      if (!this.args.tools) {
        return [];
      }
      const allTools1 = this.args.allTools;
      if (!allTools1) {
        return [];
      }
      const options1 = [];
      this.toolNames.forEach(toolId1 => {
        const tool1 = allTools1.findBy("id", toolId1);
        const toolName1 = tool1?.name;
        const toolOptions1 = tool1?.options;
        if (toolOptions1) {
          const mappedOptions1 = Object.keys(toolOptions1).map(key1 => {
            const value1 = this.args.persona.getToolOption(toolId1, key1);
            return Object.assign({}, toolOptions1[key1], {
              id: key1,
              value: value1
            });
          });
          options1.push({
            toolName: toolName1,
            options: mappedOptions1
          });
        }
      });
      return options1;
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.showToolOptions}}
          <div class="control-group">
            <label>{{I18n.t "discourse_ai.ai_persona.tool_options"}}</label>
            <div>
              {{#each this.toolOptions as |toolOption|}}
                <div class="ai-persona-editor__tool-options">
                  <div class="ai-persona-editor__tool-options-name">
                    {{toolOption.toolName}}
                  </div>
                  <div class="ai-persona-editor__tool-option-options">
                    {{#each toolOption.options as |option|}}
                      <AiPersonaToolOptionEditor @option={{option}} />
                    {{/each}}
                  </div>
                </div>
              {{/each}}
            </div>
          </div>
        {{/if}}
      
    */
    {
      "id": "Z0blnyXN",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"showToolOptions\"]],[[[1,\"      \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n        \"],[10,\"label\"],[12],[1,[28,[32,0,[\"t\"]],[\"discourse_ai.ai_persona.tool_options\"],null]],[13],[1,\"\\n        \"],[10,0],[12],[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,0,[\"toolOptions\"]]],null]],null],null,[[[1,\"            \"],[10,0],[14,0,\"ai-persona-editor__tool-options\"],[12],[1,\"\\n              \"],[10,0],[14,0,\"ai-persona-editor__tool-options-name\"],[12],[1,\"\\n                \"],[1,[30,1,[\"toolName\"]]],[1,\"\\n              \"],[13],[1,\"\\n              \"],[10,0],[14,0,\"ai-persona-editor__tool-option-options\"],[12],[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,1,[\"options\"]]],null]],null],null,[[[1,\"                  \"],[8,[32,1],null,[[\"@option\"],[[30,2]]],null],[1,\"\\n\"]],[2]],null],[1,\"              \"],[13],[1,\"\\n            \"],[13],[1,\"\\n\"]],[1]],null],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"toolOption\",\"option\"],false,[\"if\",\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/ai-persona-tool-options.js",
      "scope": () => [_discourseI18n.default, _aiPersonaToolOptionEditor.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AiPersonaToolOptions;
});