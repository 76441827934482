define("discourse/plugins/discourse-ai/lib/discourse-markdown/ai-tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setup = setup;
  function setup(helper) {
    helper.allowList(["details[class=ai-quote]"]);
  }
});