define("discourse/plugins/discourse-ai/discourse/admin/models/ai-tool", ["exports", "@ember-compat/tracked-built-ins", "discourse/models/rest"], function (_exports, _trackedBuiltIns, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CREATE_ATTRIBUTES = ["id", "name", "description", "parameters", "script", "summary", "enabled"];
  class AiTool extends _rest.default {
    createProperties() {
      return this.getProperties(CREATE_ATTRIBUTES);
    }
    updateProperties() {
      return this.getProperties(CREATE_ATTRIBUTES);
    }
    workingCopy() {
      const attrs = this.getProperties(CREATE_ATTRIBUTES);
      attrs.parameters = new _trackedBuiltIns.TrackedArray(attrs.parameters?.map(p => {
        const parameter = new _trackedBuiltIns.TrackedObject(p);

        //Backwards-compatibility code.
        // TODO(roman): Remove aug 2024. Leave only else clause.
        if (parameter.enum_values) {
          parameter.enum = new _trackedBuiltIns.TrackedArray(parameter.enum_values);
          delete parameter.enum_values;
        } else {
          parameter.enum = new _trackedBuiltIns.TrackedArray(parameter.enum);
        }
        return parameter;
      }));
      return this.store.createRecord("ai-tool", attrs);
    }
  }
  _exports.default = AiTool;
});