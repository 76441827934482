define("discourse/plugins/discourse-ai/discourse/components/ai-llm-editor", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "discourse/components/back-button", "discourse/components/d-button", "discourse-i18n", "select-kit/components/combo-box", "discourse/plugins/discourse-ai/discourse/components/ai-llm-editor-form", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _backButton, _dButton, _discourseI18n, _comboBox, _aiLlmEditorForm, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AiLlmEditor extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "presetConfigured", [_tracking.tracked], function () {
      return false;
    }))();
    #presetConfigured = (() => (dt7948.i(this, "presetConfigured"), void 0))();
    presetId = "none";
    get showPresets() {
      return this.args.model.isNew && !this.presetConfigured && !this.args.model.url;
    }
    get preConfiguredLlms() {
      let options1 = [{
        id: "none",
        name: _discourseI18n.default.t(`discourse_ai.llms.preconfigured.none`)
      }];
      this.args.llms.resultSetMeta.presets.forEach(llm1 => {
        if (llm1.models) {
          llm1.models.forEach(model1 => {
            options1.push({
              id: `${llm1.id}-${model1.name}`,
              name: model1.display_name
            });
          });
        }
      });
      return options1;
    }
    configurePreset() {
      this.presetConfigured = true;
      let [id1, model1] = this.presetId.split(/-(.*)/);
      if (id1 === "none") {
        return;
      }
      const info1 = this.args.llms.resultSetMeta.presets.findBy("id", id1);
      const modelInfo1 = info1.models.findBy("name", model1);
      this.args.model.setProperties({
        max_prompt_tokens: modelInfo1.tokens,
        tokenizer: info1.tokenizer,
        url: modelInfo1.endpoint || info1.endpoint,
        display_name: modelInfo1.display_name,
        name: modelInfo1.name,
        provider: info1.provider
      });
    }
    static #_2 = (() => dt7948.n(this.prototype, "configurePreset", [_object.action]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <BackButton
          @route="adminPlugins.show.discourse-ai-llms"
          @label="discourse_ai.llms.back"
        />
        {{#if this.showPresets}}
          <form class="form-horizontal ai-llm-editor">
            <div class="control-group">
              <label>{{I18n.t "discourse_ai.llms.preconfigured_llms"}}</label>
              <ComboBox
                @value={{this.presetId}}
                @content={{this.preConfiguredLlms}}
                class="ai-llm-editor__presets"
              />
            </div>
    
            <div class="control-group ai-llm-editor__action_panel">
              <DButton class="ai-llm-editor__next" @action={{this.configurePreset}}>
                {{I18n.t "discourse_ai.llms.next.title"}}
              </DButton>
            </div>
          </form>
        {{else}}
          <AiLlmEditorForm @model={{@model}} @llms={{@llms}} />
        {{/if}}
      
    */
    {
      "id": "r6uN6b4m",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@route\",\"@label\"],[\"adminPlugins.show.discourse-ai-llms\",\"discourse_ai.llms.back\"]],null],[1,\"\\n\"],[41,[30,0,[\"showPresets\"]],[[[1,\"      \"],[10,\"form\"],[14,0,\"form-horizontal ai-llm-editor\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n          \"],[10,\"label\"],[12],[1,[28,[32,1,[\"t\"]],[\"discourse_ai.llms.preconfigured_llms\"],null]],[13],[1,\"\\n          \"],[8,[32,2],[[24,0,\"ai-llm-editor__presets\"]],[[\"@value\",\"@content\"],[[30,0,[\"presetId\"]],[30,0,[\"preConfiguredLlms\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,0],[14,0,\"control-group ai-llm-editor__action_panel\"],[12],[1,\"\\n          \"],[8,[32,3],[[24,0,\"ai-llm-editor__next\"]],[[\"@action\"],[[30,0,[\"configurePreset\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[32,1,[\"t\"]],[\"discourse_ai.llms.next.title\"],null]],[1,\"\\n          \"]],[]]]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[32,4],null,[[\"@model\",\"@llms\"],[[30,1],[30,2]]],null],[1,\"\\n\"]],[]]],[1,\"  \"]],[\"@model\",\"@llms\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/ai-llm-editor.js",
      "scope": () => [_backButton.default, _discourseI18n.default, _comboBox.default, _dButton.default, _aiLlmEditorForm.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AiLlmEditor;
});