define("discourse/plugins/discourse-ai/discourse/components/ai-helper-custom-prompt", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/component", "@ember/helper", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "discourse/components/d-button", "discourse-common/helpers/i18n", "truth-helpers/helpers/not", "@ember/template-factory"], function (_exports, _component, _tracking, _component2, _helper, _object, _didInsert, _dButton, _i18n, _not, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AiHelperCustomPrompt extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "_customPromptInput", [_tracking.tracked]))();
    #_customPromptInput = (() => (dt7948.i(this, "_customPromptInput"), void 0))();
    setupCustomPrompt() {
      this._customPromptInput = document.querySelector(".ai-custom-prompt__input");
      this._customPromptInput.focus();
    }
    static #_2 = (() => dt7948.n(this.prototype, "setupCustomPrompt", [_object.action]))();
    sendInput() {
      return this.args.submit(this.args.promptArgs);
    }
    static #_3 = (() => dt7948.n(this.prototype, "sendInput", [_object.action]))();
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="ai-custom-prompt" {{didInsert this.setupCustomPrompt}}>
          <Input
            @value={{@value}}
            placeholder={{i18n
              "discourse_ai.ai_helper.context_menu.custom_prompt.placeholder"
            }}
            class="ai-custom-prompt__input"
            @enter={{this.sendInput}}
          />
    
          <DButton
            @icon="discourse-sparkles"
            @action={{fn @submit @promptArgs}}
            @disabled={{not @value.length}}
            class="ai-custom-prompt__submit btn-primary"
          />
        </div>
      
    */
    {
      "id": "R9H8RJGc",
      "block": "[[[1,\"\\n    \"],[11,0],[24,0,\"ai-custom-prompt\"],[4,[32,0],[[30,0,[\"setupCustomPrompt\"]]],null],[12],[1,\"\\n      \"],[8,[32,1],[[16,\"placeholder\",[28,[32,2],[\"discourse_ai.ai_helper.context_menu.custom_prompt.placeholder\"],null]],[24,0,\"ai-custom-prompt__input\"]],[[\"@value\",\"@enter\"],[[30,1],[30,0,[\"sendInput\"]]]],null],[1,\"\\n\\n      \"],[8,[32,3],[[24,0,\"ai-custom-prompt__submit btn-primary\"]],[[\"@icon\",\"@action\",\"@disabled\"],[\"discourse-sparkles\",[28,[32,4],[[30,2],[30,3]],null],[28,[32,5],[[30,1,[\"length\"]]],null]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@value\",\"@submit\",\"@promptArgs\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/ai-helper-custom-prompt.js",
      "scope": () => [_didInsert.default, _component2.Input, _i18n.default, _dButton.default, _helper.fn, _not.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AiHelperCustomPrompt;
});