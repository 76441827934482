define("discourse/plugins/discourse-ai/discourse/connectors/after-search-result-entry/search-result-decoration", ["exports", "discourse-common/helpers/d-icon", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _dIcon, _i18n, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SearchResultDecoration = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div
      class="ai-result__icon"
      title={{i18n "discourse_ai.embeddings.ai_generated_result"}}
    >
      {{icon "discourse-sparkles"}}
    </div>
  
  */
  {
    "id": "+zzrKPCx",
    "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"ai-result__icon\"],[15,\"title\",[28,[32,0],[\"discourse_ai.embeddings.ai_generated_result\"],null]],[12],[1,\"\\n    \"],[1,[28,[32,1],[\"discourse-sparkles\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/connectors/after-search-result-entry/search-result-decoration.js",
    "scope": () => [_i18n.default, _dIcon.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  var _default = _exports.default = SearchResultDecoration;
});