define("discourse/plugins/discourse-ai/discourse/admin/models/ai-llm", ["exports", "discourse/lib/ajax", "discourse/models/rest"], function (_exports, _ajax, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AiLlm extends _rest.default {
    createProperties() {
      return this.getProperties("id", "display_name", "name", "provider", "tokenizer", "max_prompt_tokens", "url", "api_key", "enabled_chat_bot", "provider_params", "vision_enabled");
    }
    updateProperties() {
      const attrs = this.createProperties();
      attrs.id = this.id;
      return attrs;
    }
    async testConfig() {
      return await (0, _ajax.ajax)(`/admin/plugins/discourse-ai/ai-llms/test.json`, {
        data: {
          ai_llm: this.createProperties()
        }
      });
    }
  }
  _exports.default = AiLlm;
});