define("discourse/plugins/discourse-ai/discourse/components/reviewable-ai-chat-message", ["exports", "@ember/component", "@glimmer/component", "discourse/plugins/chat/discourse/models/chat-channel", "@ember/template-factory"], function (_exports, _component, _component2, _chatChannel, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.chatChannel}}
    <div class="flagged-post-header">
      <LinkTo
        @route="chat.channel.near-message"
        @models={{array
          this.chatChannel.slugifiedTitle
          this.chatChannel.id
          @reviewable.target_id
        }}
      >
        <ChatChannelTitle @channel={{this.chatChannel}} />
      </LinkTo>
    </div>
  {{/if}}
  
  <div class="post-contents-wrapper">
    <ReviewableCreatedBy @user={{@reviewable.target_created_by}} @tagName="" />
    <div class="post-contents">
      <ReviewablePostHeader
        @reviewable={{@reviewable}}
        @createdBy={{@reviewable.target_created_by}}
        @tagName=""
      />
  
      <div class="post-body">
        {{html-safe (or @reviewable.payload.message_cooked @reviewable.cooked)}}
      </div>
  
      {{yield}}
  
      <ModelAccuracies @accuracies={{@reviewable.payload.accuracies}} />
    </div>
  </div>
  */
  {
    "id": "yKOx21Wk",
    "block": "[[[41,[30,0,[\"chatChannel\"]],[[[1,\"  \"],[10,0],[14,0,\"flagged-post-header\"],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@route\",\"@models\"],[\"chat.channel.near-message\",[28,[37,2],[[30,0,[\"chatChannel\",\"slugifiedTitle\"]],[30,0,[\"chatChannel\",\"id\"]],[30,1,[\"target_id\"]]],null]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,3],null,[[\"@channel\"],[[30,0,[\"chatChannel\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[10,0],[14,0,\"post-contents-wrapper\"],[12],[1,\"\\n  \"],[8,[39,4],null,[[\"@user\",\"@tagName\"],[[30,1,[\"target_created_by\"]],\"\"]],null],[1,\"\\n  \"],[10,0],[14,0,\"post-contents\"],[12],[1,\"\\n    \"],[8,[39,5],null,[[\"@reviewable\",\"@createdBy\",\"@tagName\"],[[30,1],[30,1,[\"target_created_by\"]],\"\"]],null],[1,\"\\n\\n    \"],[10,0],[14,0,\"post-body\"],[12],[1,\"\\n      \"],[1,[28,[35,6],[[28,[37,7],[[30,1,[\"payload\",\"message_cooked\"]],[30,1,[\"cooked\"]]],null]],null]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[18,2,null],[1,\"\\n\\n    \"],[8,[39,9],null,[[\"@accuracies\"],[[30,1,[\"payload\",\"accuracies\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@reviewable\",\"&default\"],false,[\"if\",\"link-to\",\"array\",\"chat-channel-title\",\"reviewable-created-by\",\"reviewable-post-header\",\"html-safe\",\"or\",\"yield\",\"model-accuracies\"]]",
    "moduleName": "discourse/plugins/discourse-ai/discourse/components/reviewable-ai-chat-message.hbs",
    "isStrictMode": false
  });
  class ReviewableAiChatMessage extends _component2.default {
    get chatChannel() {
      if (!this.args.reviewable.chat_channel) {
        return;
      }
      return _chatChannel.default.create(this.args.reviewable.chat_channel);
    }
  }
  _exports.default = ReviewableAiChatMessage;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ReviewableAiChatMessage);
});