define("discourse/plugins/discourse-ai/discourse/components/ai-summary-skeleton", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/helper", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "@ember/render-modifiers/modifiers/did-update", "@ember/render-modifiers/modifiers/will-destroy", "@ember/runloop", "discourse/helpers/concat-class", "discourse-common/helpers/i18n", "discourse-common/lib/later", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _helper, _object, _didInsert, _didUpdate, _willDestroy, _runloop, _concatClass, _i18n, _later, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let Block = class Block {
    static #_ = (() => dt7948.g(this.prototype, "show", [_tracking.tracked], function () {
      return false;
    }))();
    #show = (() => (dt7948.i(this, "show"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "shown", [_tracking.tracked], function () {
      return false;
    }))();
    #shown = (() => (dt7948.i(this, "shown"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "blinking", [_tracking.tracked], function () {
      return false;
    }))();
    #blinking = (() => (dt7948.i(this, "blinking"), void 0))();
    constructor() {
      let args1 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.show = args1.show ?? false;
      this.shown = args1.shown ?? false;
    }
  };
  const BLOCKS_SIZE = 20; // changing this requires to change css accordingly
  class AiSummarySkeleton extends _component.default {
    blocks = (() => [...Array.from({
      length: BLOCKS_SIZE
    }, () => new Block())])();
    #nextBlockBlinkingTimer;
    #blockBlinkingTimer;
    #blockShownTimer;
    setupAnimation() {
      this.blocks.firstObject.show = true;
      this.blocks.firstObject.shown = true;
    }
    static #_ = (() => dt7948.n(this.prototype, "setupAnimation", [_object.action]))();
    onBlinking(block1) {
      if (!block1.blinking) {
        return;
      }
      block1.show = false;
      this.#nextBlockBlinkingTimer = (0, _later.default)(this, () => {
        this.#nextBlock(block1).blinking = true;
      }, 250);
      this.#blockBlinkingTimer = (0, _later.default)(this, () => {
        block1.blinking = false;
      }, 500);
    }
    static #_2 = (() => dt7948.n(this.prototype, "onBlinking", [_object.action]))();
    onShowing(block1) {
      if (!block1.show) {
        return;
      }
      this.#blockShownTimer = (0, _later.default)(this, () => {
        this.#nextBlock(block1).show = true;
        this.#nextBlock(block1).shown = true;
        if (this.blocks.lastObject === block1) {
          this.blocks.firstObject.blinking = true;
        }
      }, 250);
    }
    static #_3 = (() => dt7948.n(this.prototype, "onShowing", [_object.action]))();
    teardownAnimation() {
      (0, _runloop.cancel)(this.#blockShownTimer);
      (0, _runloop.cancel)(this.#nextBlockBlinkingTimer);
      (0, _runloop.cancel)(this.#blockBlinkingTimer);
    }
    static #_4 = (() => dt7948.n(this.prototype, "teardownAnimation", [_object.action]))();
    #nextBlock(currentBlock1) {
      if (currentBlock1 === this.blocks.lastObject) {
        return this.blocks.firstObject;
      } else {
        return this.blocks.objectAt(this.blocks.indexOf(currentBlock1) + 1);
      }
    }
    static #_5 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="ai-summary__container">
          <ul class="ai-summary__list" {{didInsert this.setupAnimation}}>
            {{#each this.blocks as |block|}}
              <li
                class={{concatClass
                  "ai-summary__list-item"
                  (if block.show "show")
                  (if block.shown "is-shown")
                  (if block.blinking "blink")
                }}
                {{didUpdate (fn this.onBlinking block) block.blinking}}
                {{didUpdate (fn this.onShowing block) block.show}}
                {{willDestroy this.teardownAnimation}}
              ></li>
            {{/each}}
          </ul>
    
          <span>
            <div class="ai-summary__generating-text">
              {{i18n "summary.in_progress"}}
            </div>
            <span class="ai-summary__indicator-wave">
              <span class="ai-summary__indicator-dot">.</span>
              <span class="ai-summary__indicator-dot">.</span>
              <span class="ai-summary__indicator-dot">.</span>
            </span>
          </span>
        </div>
      
    */
    {
      "id": "QTV7JPE5",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"ai-summary__container\"],[12],[1,\"\\n      \"],[11,\"ul\"],[24,0,\"ai-summary__list\"],[4,[32,0],[[30,0,[\"setupAnimation\"]]],null],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"blocks\"]]],null]],null],null,[[[1,\"          \"],[11,\"li\"],[16,0,[28,[32,1],[\"ai-summary__list-item\",[52,[30,1,[\"show\"]],\"show\"],[52,[30,1,[\"shown\"]],\"is-shown\"],[52,[30,1,[\"blinking\"]],\"blink\"]],null]],[4,[32,2],[[28,[32,3],[[30,0,[\"onBlinking\"]],[30,1]],null],[30,1,[\"blinking\"]]],null],[4,[32,2],[[28,[32,3],[[30,0,[\"onShowing\"]],[30,1]],null],[30,1,[\"show\"]]],null],[4,[32,4],[[30,0,[\"teardownAnimation\"]]],null],[12],[13],[1,\"\\n\"]],[1]],null],[1,\"      \"],[13],[1,\"\\n\\n      \"],[10,1],[12],[1,\"\\n        \"],[10,0],[14,0,\"ai-summary__generating-text\"],[12],[1,\"\\n          \"],[1,[28,[32,5],[\"summary.in_progress\"],null]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,1],[14,0,\"ai-summary__indicator-wave\"],[12],[1,\"\\n          \"],[10,1],[14,0,\"ai-summary__indicator-dot\"],[12],[1,\".\"],[13],[1,\"\\n          \"],[10,1],[14,0,\"ai-summary__indicator-dot\"],[12],[1,\".\"],[13],[1,\"\\n          \"],[10,1],[14,0,\"ai-summary__indicator-dot\"],[12],[1,\".\"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"block\"],false,[\"each\",\"-track-array\",\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/ai-summary-skeleton.js",
      "scope": () => [_didInsert.default, _concatClass.default, _didUpdate.default, _helper.fn, _willDestroy.default, _i18n.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AiSummarySkeleton;
});