define("discourse/plugins/discourse-ai/discourse/components/ai-bot-header-icon", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse-common/helpers/i18n", "discourse/plugins/discourse-ai/discourse/lib/ai-bot-helper", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _i18n, _aiBotHelper, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AiBotHeaderIcon extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "composer", [_service.service]))();
    #composer = (() => (dt7948.i(this, "composer"), void 0))();
    get bots() {
      const availableBots1 = this.currentUser.ai_enabled_chat_bots.filter(bot1 => !bot1.is_persosna).filter(Boolean);
      return availableBots1 ? availableBots1.map(bot1 => bot1.model_name) : [];
    }
    get showHeaderButton() {
      return this.bots.length > 0 && this.siteSettings.ai_bot_add_to_header;
    }
    compose() {
      (0, _aiBotHelper.composeAiBotMessage)(this.bots[0], this.composer);
    }
    static #_4 = (() => dt7948.n(this.prototype, "compose", [_object.action]))();
    static #_5 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.showHeaderButton}}
          <li>
            <DButton
              @action={{this.compose}}
              @icon="robot"
              title={{i18n "discourse_ai.ai_bot.shortcut_title"}}
              class="ai-bot-button icon btn-flat"
            />
          </li>
        {{/if}}
      
    */
    {
      "id": "NL7Zjh9n",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"showHeaderButton\"]],[[[1,\"      \"],[10,\"li\"],[12],[1,\"\\n        \"],[8,[32,0],[[16,\"title\",[28,[32,1],[\"discourse_ai.ai_bot.shortcut_title\"],null]],[24,0,\"ai-bot-button icon btn-flat\"]],[[\"@action\",\"@icon\"],[[30,0,[\"compose\"]],\"robot\"]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/ai-bot-header-icon.js",
      "scope": () => [_dButton.default, _i18n.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AiBotHeaderIcon;
});