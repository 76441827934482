define("discourse/plugins/discourse-ai/discourse/lib/utilities", ["exports", "@ember/template", "discourse/lib/utilities"], function (_exports, _template, _utilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.IMAGE_MARKDOWN_REGEX = void 0;
  _exports.jsonToHtml = jsonToHtml;
  const IMAGE_MARKDOWN_REGEX = _exports.IMAGE_MARKDOWN_REGEX = /!\[(.*?)\|(\d{1,4}x\d{1,4})(,\s*\d{1,3}%)?(.*?)\]\((upload:\/\/.*?)\)(?!(.*`))/g;
  function jsonToHtml(json) {
    if (json === null) {
      return "null";
    }
    if (typeof json !== "object") {
      return (0, _utilities.escapeExpression)(json);
    }
    let html = "<ul>";
    for (let [key, value] of Object.entries(json)) {
      html += "<li>";
      key = (0, _utilities.escapeExpression)(key);
      if (typeof value === "object" && Array.isArray(value)) {
        html += `<strong>${key}:</strong> ${jsonToHtml(value)}`;
      } else if (typeof value === "object") {
        html += `<strong>${key}:</strong> <ul><li>${jsonToHtml(value)}</li></ul>`;
      } else {
        if (typeof value === "string") {
          value = (0, _utilities.escapeExpression)(value).replace(/\n/g, "<br>");
        }
        html += `<strong>${key}:</strong> ${value}`;
      }
      html += "</li>";
    }
    html += "</ul>";
    return (0, _template.htmlSafe)(html);
  }
});