define("discourse/plugins/discourse-ai/discourse/connectors/editor-preview/ai-image-caption-container", ["exports", "@glimmer/component", "@ember/modifier", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "@ember/render-modifiers/modifiers/did-update", "@ember/render-modifiers/modifiers/will-destroy", "@ember/service", "discourse/components/conditional-loading-spinner", "discourse/components/d-button", "discourse/components/d-textarea", "discourse/modifiers/auto-focus", "discourse-common/helpers/d-icon", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _modifier, _object, _didInsert, _didUpdate, _willDestroy, _service, _conditionalLoadingSpinner, _dButton, _dTextarea, _autoFocus, _dIcon, _i18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AiImageCaptionContainer extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "imageCaptionPopup", [_service.inject]))();
    #imageCaptionPopup = (() => (dt7948.i(this, "imageCaptionPopup"), void 0))();
    updateCaption(event1) {
      event1.preventDefault();
      this.imageCaptionPopup.newCaption = event1.target.value;
    }
    static #_2 = (() => dt7948.n(this.prototype, "updateCaption", [_object.action]))();
    saveCaption() {
      this.imageCaptionPopup.updateCaption();
      this.hidePopup();
    }
    static #_3 = (() => dt7948.n(this.prototype, "saveCaption", [_object.action]))();
    resizeTextarea(target1) {
      const style1 = window.getComputedStyle(target1);
      // scrollbars will show based on scrollHeight alone
      // so we need to consider borders too
      const borderTopWidth1 = parseInt(style1.borderTopWidth, 10);
      const borderBottomWidth1 = parseInt(style1.borderBottomWidth, 10);
      target1.scrollTop = 0;
      target1.style.height = `${target1.scrollHeight + borderTopWidth1 + borderBottomWidth1}px`;
    }
    static #_4 = (() => dt7948.n(this.prototype, "resizeTextarea", [_object.action]))();
    hidePopup() {
      this.imageCaptionPopup.showPopup = false;
      if (this.imageCaptionPopup._request) {
        this.imageCaptionPopup._request.abort();
        this.imageCaptionPopup._request = null;
        this.imageCaptionPopup.toggleLoadingState(false);
      }
    }
    static #_5 = (() => dt7948.n(this.prototype, "hidePopup", [_object.action]))();
    static #_6 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.imageCaptionPopup.showPopup}}
          <div
            class="composer-popup education-message ai-caption-popup"
            {{willDestroy this.hidePopup}}
          >
            <ConditionalLoadingSpinner
              @condition={{this.imageCaptionPopup.loading}}
            >
              <DTextarea
                {{didInsert this.resizeTextarea}}
                {{didUpdate this.resizeTextarea this.imageCaptionPopup.newCaption}}
                @value={{this.imageCaptionPopup.newCaption}}
                {{on "change" this.updateCaption}}
                {{autoFocus}}
              />
            </ConditionalLoadingSpinner>
    
            <div class="actions">
              <DButton
                class="btn-primary"
                @label="discourse_ai.ai_helper.image_caption.save_caption"
                @icon="check"
                @action={{this.saveCaption}}
              />
              <DButton
                class="btn-flat cancel-request"
                @label="cancel"
                @action={{this.hidePopup}}
              />
    
              <span class="credits">
                {{icon "discourse-sparkles"}}
                <span>{{i18n "discourse_ai.ai_helper.image_caption.credits"}}</span>
              </span>
            </div>
          </div>
        {{/if}}
      
    */
    {
      "id": "84lcZtW4",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"imageCaptionPopup\",\"showPopup\"]],[[[1,\"      \"],[11,0],[24,0,\"composer-popup education-message ai-caption-popup\"],[4,[32,0],[[30,0,[\"hidePopup\"]]],null],[12],[1,\"\\n        \"],[8,[32,1],null,[[\"@condition\"],[[30,0,[\"imageCaptionPopup\",\"loading\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[8,[32,2],[[4,[32,3],[[30,0,[\"resizeTextarea\"]]],null],[4,[32,4],[[30,0,[\"resizeTextarea\"]],[30,0,[\"imageCaptionPopup\",\"newCaption\"]]],null],[4,[32,5],[\"change\",[30,0,[\"updateCaption\"]]],null],[4,[32,6],null,null]],[[\"@value\"],[[30,0,[\"imageCaptionPopup\",\"newCaption\"]]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[10,0],[14,0,\"actions\"],[12],[1,\"\\n          \"],[8,[32,7],[[24,0,\"btn-primary\"]],[[\"@label\",\"@icon\",\"@action\"],[\"discourse_ai.ai_helper.image_caption.save_caption\",\"check\",[30,0,[\"saveCaption\"]]]],null],[1,\"\\n          \"],[8,[32,7],[[24,0,\"btn-flat cancel-request\"]],[[\"@label\",\"@action\"],[\"cancel\",[30,0,[\"hidePopup\"]]]],null],[1,\"\\n\\n          \"],[10,1],[14,0,\"credits\"],[12],[1,\"\\n            \"],[1,[28,[32,8],[\"discourse-sparkles\"],null]],[1,\"\\n            \"],[10,1],[12],[1,[28,[32,9],[\"discourse_ai.ai_helper.image_caption.credits\"],null]],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/connectors/editor-preview/ai-image-caption-container.js",
      "scope": () => [_willDestroy.default, _conditionalLoadingSpinner.default, _dTextarea.default, _didInsert.default, _didUpdate.default, _modifier.on, _autoFocus.default, _dButton.default, _dIcon.default, _i18n.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AiImageCaptionContainer;
});