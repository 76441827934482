define("discourse/plugins/discourse-ai/initializers/ai-semantic-search", ["exports", "discourse/lib/api"], function (_exports, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _api.apiInitializer)("1.15.0", api => {
    api.modifyClass("component:search-result-entry", {
      pluginId: "discourse-ai",
      classNameBindings: ["bulkSelectEnabled", "post.generatedByAI:ai-result"]
    });
  });
});