define("discourse/plugins/discourse-ai/discourse/components/thumbnail-suggestion-item", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/helper", "@ember/object", "discourse/components/d-button", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _helper, _object, _dButton, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ThumbnailSuggestionItem extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "selected", [_tracking.tracked], function () {
      return false;
    }))();
    #selected = (() => (dt7948.i(this, "selected"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "selectIcon", [_tracking.tracked], function () {
      return "far-circle";
    }))();
    #selectIcon = (() => (dt7948.i(this, "selectIcon"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "selectLabel", [_tracking.tracked], function () {
      return "discourse_ai.ai_helper.thumbnail_suggestions.select";
    }))();
    #selectLabel = (() => (dt7948.i(this, "selectLabel"), void 0))();
    toggleSelection(thumbnail1) {
      if (this.selected) {
        this.selectIcon = "far-circle";
        this.selectLabel = "discourse_ai.ai_helper.thumbnail_suggestions.select";
        this.selected = false;
        return this.args.removeSelection(thumbnail1);
      }
      this.selectIcon = "check-circle";
      this.selectLabel = "discourse_ai.ai_helper.thumbnail_suggestions.selected";
      this.selected = true;
      return this.args.addSelection(thumbnail1);
    }
    static #_4 = (() => dt7948.n(this.prototype, "toggleSelection", [_object.action]))();
    static #_5 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="ai-thumbnail-suggestions__item">
          <DButton
            @icon={{this.selectIcon}}
            @label={{this.selectLabel}}
            @action={{fn this.toggleSelection @thumbnail}}
            class={{if this.selected "btn-primary"}}
          />
          <img
            src={{@thumbnail.url}}
            loading="lazy"
            width={{@thumbnail.thumbnail_width}}
            height={{@thumbnail.thumbnail_height}}
          />
        </div>
      
    */
    {
      "id": "BsBgpDKw",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"ai-thumbnail-suggestions__item\"],[12],[1,\"\\n      \"],[8,[32,0],[[16,0,[52,[30,0,[\"selected\"]],\"btn-primary\"]]],[[\"@icon\",\"@label\",\"@action\"],[[30,0,[\"selectIcon\"]],[30,0,[\"selectLabel\"]],[28,[32,1],[[30,0,[\"toggleSelection\"]],[30,1]],null]]],null],[1,\"\\n      \"],[10,\"img\"],[15,\"src\",[30,1,[\"url\"]]],[14,\"loading\",\"lazy\"],[15,\"width\",[30,1,[\"thumbnail_width\"]]],[15,\"height\",[30,1,[\"thumbnail_height\"]]],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@thumbnail\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/thumbnail-suggestion-item.js",
      "scope": () => [_dButton.default, _helper.fn],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ThumbnailSuggestionItem;
});